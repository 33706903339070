import { withRouter, Redirect } from 'react-router-dom'
import React from 'react'
import intl from 'react-intl-universal'
import { connect } from 'react-redux'
import { logUserIn, getZones } from '../actions/AuthActions'

const mapStateToProps = state => ({
  invalid: state.auth.invalid,
  authenticated: state.auth.session.token !== false,
  zones: state.config.zones,
  lang: state.auth.lang
})

const mapDispatchToProps = dispatch => ({
    logInAction: (login, password, packing_zone, packing_zone_password) => dispatch(
      logUserIn(login, password, packing_zone, packing_zone_password)
    ),
    getZonesAction: () => dispatch(getZones())
})

class LoginFormComponent extends React.Component {
  constructor() {
    super()
    this.state = {
      login: '',
      password: '',
      rma_zone: '',
      rma_zone_password: ''
    }
  }

  componentDidMount(){
    const {getZonesAction} = this.props
    getZonesAction()
  }

  componentDidUpdate(){
    const {zones} = this.props
    const {rma_zone} = this.state
    if (zones && zones.length > 0 && !rma_zone){
      this.setState({
        rma_zone: zones[0]
      })
    }
  }

  handleChange = (event) => {
    const {target} = event
    this.setState({
      [target.name]: target.value
    })
  }

  handleSubmit = (event) => {
    const {login, password, rma_zone, rma_zone_password} = this.state
    const {logInAction} = this.props
    event.preventDefault()
    logInAction(login, password, rma_zone, rma_zone_password)
  }

  render() {
    const {
      zones,
      authenticated,
      invalid
    } = this.props
    const {
      login,
      password,
      rma_zone,
      rma_zone_password
    } = this.state
    return (
      <div className="center_wrapper">
        { !authenticated ? (
          <div className="login_form">
            <form onSubmit={this.handleSubmit}>
              <h2>{intl.get('Zaloguj się, aby rozpocząć')}</h2>
              <div className="field">
                <input
                  placeholder=" "
                  name="login"
                  id="f-login"
                  type="text"
                  value={login}
                  onChange={this.handleChange} />
                <label htmlFor="f-login">login</label>
              </div>
              <div className="field">
                <input
                  placeholder=" "
                  name="password"
                  id="f-password"
                  type="password"
                  value={password}
                  onChange={this.handleChange} />
                <label htmlFor="f-password">{intl.get('hasło')}</label>
              </div>
              <div className="field">
                <select
                  name="rma_zone"
                  id="f-rma_zone"
                  value={rma_zone}
                  onChange={this.handleChange}>
                  {zones && zones.map((zone, i) =>
                    <option key={i} value={zone}>{zone}</option>
                  )}
                </select>
                <label htmlFor="f-rma_zone">{intl.get('stanowisko rma')}</label>
              </div>
              <div className="field">
                <input
                  placeholder=" "
                  name="rma_zone_password"
                  id="f-rma_zone_password"
                  type="password"
                  value={rma_zone_password}
                  onChange={this.handleChange} />
                <label htmlFor="f-rma_zone_password">{intl.get('hasło stanowiska rma')}</label>
              </div>
              <input type="submit" value={intl.get('Zaloguj')} />
              { invalid &&
                <div className="login-failed">
                  {intl.get('Nieprawidłowy login lub jedno z haseł!')}
                </div>
              }
            </form>
          </div>
        ) : (
          <Redirect to='/' />
        )}
      </div>
    )
  }
}
const LoginForm = connect(mapStateToProps, mapDispatchToProps)(LoginFormComponent)
export default withRouter(LoginForm)
