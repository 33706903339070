import { withRouter, Redirect } from 'react-router-dom'
import React from 'react'
import intl from 'react-intl-universal'
import { connect } from 'react-redux'
import { deselectOrder } from '../actions/RmaActions'

const mapStateToProps = state => ({
  authenticated: state.auth.session.id !== false,
  order: state.rma.order,
  done: state.rma.done,
  lang: state.auth.lang
})

const mapDispatchToProps = dispatch => ({
  deselectOrderAction: () => dispatch(deselectOrder())
})

class DoneComponent extends React.Component {
  confirmDone = () => {
    const {
      deselectOrderAction
    } = this.props
    deselectOrderAction()
  }

  render() {
    const { authenticated, order, done } = this.props
    if(!authenticated) return (<Redirect to='/login' />)
    return (
      <div className="container">
        <div className="ramka done">
          <h2>
            {intl.get('Zapisano!')}
            <div className="subheader">{intl.get('Wszystkie dane zostały poprawnie zapisane w systemie.')}</div>
          </h2>
          { order &&
            <>
              <div className="summary">
                <h4>{intl.get('Zgłoszenie')} {done} {intl.get('dla zamówienia')} {order.order_name}</h4>
                <div className="notes f_group">
                  <label>{intl.get('Uwagi klienta')}:</label>
                  <div style={{'fontStyle': 'italic'}}>{order.client_note}</div>
                </div>
                <div className="date_order f_group">
                  <label>{intl.get('Data zamówienia')}:</label>
                  <div>{order.date_order.substring(0, order.date_order.lastIndexOf(':'))}</div>
                </div>
                <div className="amount_total f_group">
                  <label>{intl.get('Kwota zamówienia')}:</label>
                  <div>{order.amount_total}</div>
                </div>
                <div className="delivery f_group">
                  <label>{intl.get('Adres dostawy')}:</label>
                  <div>
                    <div>{order.delivery_name}</div>
                    <div>{order.delivery_street}</div>
                    <div>{order.delivery_zip} {order.delivery_city}</div>
                  </div>
                </div>
              </div>
              <div className="buttons_wrap">
                <button onClick={this.confirmDone}>{intl.get('Kontynuuj')}</button>
              </div>
            </>
          }
        </div>
      </div>
    )
  }
}

const Done = connect(mapStateToProps, mapDispatchToProps)(DoneComponent)
export default withRouter(Done)
