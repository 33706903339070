import React from 'react'
import logo from '../assets/idea.png'
import intl from 'react-intl-universal'

function Spinner() {
  return (
    <div className="loaderWrap" style={{display: 'none'}}>
      <div className="loader">
        <div className="spinner">
          <img src={logo} alt="logo"/>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 150 120" width="90" height="75">
            <g id="g10">
              <g id="g12">
                <g id="Clip-Path: g14" clipPath="url(#cp1)">
                  <g id="g14">
                    <g id="sqrs" mask="url(#hole)">
                      <g id="g24">
                        <path id="sqr1" className="shp0" d="M38 22L74 22L74 58L38 58L38 22Z"/>
                      </g>
                      <g id="g28">
                        <path id="sqr2" className="shp0 spin2" d="M77 61L113 61L113 97L77 97L77 61Z"/>
                      </g>
                    </g>
                    <path id="path32" fillRule="evenodd" className="shp1" d="M138.46 37.49L138.46 81.48L13.53 81.48L13.53 37.49L138.46 37.49ZM57.53 78.33L94.46 78.33L94.46 40.65L57.53 40.65L57.53 78.33ZM16.69 78.33L53.67 78.33L53.67 40.65L16.69 40.65L16.69 78.33ZM135.31 40.65L97.66 40.65L97.66 78.33L135.31 78.33L135.31 40.65Z" />
                    <g id="g34">
                      <path id="path36" className="shp1" fill="black" d="M43.23 51.28L43.23 47.77L27.63 47.77L27.63 70.52L43.23 70.52L43.23 67.01L31.62 67.01L31.62 60.7L42.99 60.7L42.99 57.18L31.62 57.18L31.62 51.28L43.23 51.28Z" />
                    </g>
                    <mask id="hole">
                      <rect x="0" y="0" width="150" height="130" fill="white"/>
                      <rect x="0" y="32" width="150" height="55" fill="black"/>
                    </mask>
                    <g id="g38">
                      <path id="path40" fillRule="evenodd" className="shp1" d="M85.23 70.52L80.63 70.52L75.58 62.03L71.62 62.03L71.62 70.52L67.63 70.52L67.63 47.77L77.63 47.77C82.13 47.77 85.06 50.7 85.06 54.9C85.06 58.96 82.4 61.14 79.67 61.58L85.23 70.52ZM77.08 58.51C79.33 58.51 80.97 57.08 80.97 54.9C80.97 52.71 79.33 51.28 77.08 51.28L71.62 51.28L71.62 58.51L77.08 58.51Z" />
                    </g>
                    <g id="g42">
                      <path id="path44" fillRule="evenodd" className="shp1" d="M108.3 70.52L108.3 47.77L118.3 47.77C123.08 47.77 125.74 51.04 125.74 54.9C125.74 58.75 123.04 62.03 118.3 62.03L112.29 62.03L112.29 70.52L108.3 70.52ZM112.29 58.51L117.75 58.51C120.01 58.51 121.65 57.08 121.65 54.9C121.65 52.71 120.01 51.28 117.75 51.28L112.29 51.28L112.29 58.51Z" />
                    </g>
                  </g>
                </g>
              </g>
              <path id="Kształt 1" className="shp2" d="" />
            </g>
          </svg>
        </div>
        <span>{intl.get('Trwa ładowanie...')}</span>
      </div>
    </div>
  )
}

export default Spinner
