import { createStore, applyMiddleware, compose } from 'redux'
import ReduxThunk from 'redux-thunk'
import { createBrowserHistory } from 'history'
import { routerMiddleware } from 'connected-react-router'
import createRootReducer from '../reducers'

export const history = createBrowserHistory({
    hashType: 'slash',
    getUserConfirmation: (message, callback) => callback(window.confirm(message))
})

const middlewares = [ReduxThunk, routerMiddleware(history)]
const enhancers = [applyMiddleware(...middlewares)]

const configureStore = function(initialState = {}) {
  return createStore(
    createRootReducer(history),
    initialState,
    compose(...enhancers)
  )
}

const store = configureStore()
export default store
