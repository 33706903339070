import React from 'react';
import { render } from 'react-dom'
import { Provider } from 'react-redux'
import Popup from 'react-popup'
import store from './store'
import Routes from './routes'

render(
  <Provider store={store}>
    <Routes/>
  </Provider>,
  document.getElementById('root')
)
render(
  <Popup />,
  document.getElementById('popupContainer')
)

export default store
