import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import auth from './auth'
import rma from './rma'
import config from './config'

export default (history) => combineReducers({
  router: connectRouter(history),
  auth,
  rma,
  config
})
