import Popup from 'react-popup'
import intl from 'react-intl-universal'
import { GET_ORDER, RESET_ORDER, SET_DONE, API_ERROR } from '../constants/ActionTypes'

const initialState = {
  order: false,
  orders: false,
  mode: false,
  done: false
}

export default function rma(state = initialState, action) {
  switch (action.type) {
    case GET_ORDER:
      if(!action.payload.data){
        Popup.alert(intl.get('Nie znaleziono zamówienia'))
      }
      if(action.payload.data.already_returned){
        Popup.alert(intl.get('Do tego zamówienia był już wykonywany zwrot'))
      }
      let res = { ...state,
        mode: action.payload.mode
      }
      if(action.payload.data.length > 1){
        res.order = false
        res.orders = action.payload.data
      } else {
        res.orders = false
        res.order = action.payload.data
      }
      return res
    case RESET_ORDER:
      return initialState
    case SET_DONE:
      return {
        ...state,
        done: action.payload.rma_name
      }
    case API_ERROR:
      Popup.alert(action.message || intl.get('Wystąpił nieznany błąd'))
      return { ...state }
    default:
      return state
  }
}
