import { withRouter, Redirect } from 'react-router-dom'
import React from 'react'
import intl from 'react-intl-universal'
import { connect } from 'react-redux'
import { getForm, deselectOrder } from '../actions/RmaActions'
import calendar from '../assets/ikonki--12px--calendar.png'
import amount from '../assets/icons/amount.png'

const mapStateToProps = state => ({
  authenticated: state.auth.session.id !== false,
  mode: state.rma.mode,
  orders: state.rma.orders,
  lang: state.auth.lang
})

const mapDispatchToProps = dispatch => ({
  getFormAction: (code, id) => dispatch(getForm(code, id)),
  deselectOrdersAction: () => dispatch(deselectOrder())
})

class OrdersComponent extends React.Component {
  getOrderListItem = (order) => {
    return (
      <div onClick={this.selectOrder}
           data-id={order.id}
           data-code={order.code}
           key={order.id}
           className="elem-tile">
        <h5>{order.name}</h5>
        <div className="infoRow">
          <label><img src={calendar} alt="calendar" />{intl.get('Data zamówienia')}:</label>
          <div>{order.date_order.substring(0, order.date_order.lastIndexOf(':'))}</div>
        </div>
        <div className="infoRow">
          <label><img src={amount} alt="calendar" />{intl.get('Kwota zamówienia')}:</label>
          <div>{order.amount_total}</div>
        </div>
      </div>
    )
  }

  selectOrder = (event) => {
    const {getFormAction} = this.props
    const code = event.currentTarget.getAttribute('data-code')
    const id = parseInt(event.currentTarget.getAttribute('data-id'))
    getFormAction(code, id)
  }

  deselectOrders = (event) => {
    const {deselectOrdersAction} = this.props
    deselectOrdersAction()
  }

  render() {
    const {authenticated, orders} = this.props

    if(!authenticated) return (<Redirect to='/login' />)

    let orderListItems

    if (orders) {
      orderListItems = orders.map(order => this.getOrderListItem(order))
      return (
        <div>
          <h1>
            <div className="breadcrumb">
              <span>{intl.get('Znalezione zamówienia')}:</span>
            </div>
          </h1>
          <div className="elem-list">
            {orderListItems}
          </div>
          <div className="rma_footer">
            <button className="pull-left secondary" onClick={this.deselectOrders}>{intl.get('Anuluj')}</button>
          </div>
        </div>
      )
    } else {
      return (<div></div>)
    }
  }
}
const Orders = connect(mapStateToProps, mapDispatchToProps)(OrdersComponent)
export default withRouter(Orders)
