// Common
export const API_ERROR = 'API_ERROR'
export const SET_LANG = 'SET_LANG'

// Authentication
export const LOGIN_USER = 'LOGIN_USER'
export const LOGOUT_USER = 'LOGOUT_USER'
export const SET_HIGH_CONTRAST = 'SET_HIGH_CONTRAST'
export const LOGIN_USER_ERROR = 'LOGIN_USER_ERROR'
export const GET_CONFIG = 'GET_CONFIG'
export const GET_ZONES = 'GET_ZONES'

//Order
export const GET_ORDER = 'GET_ORDER'
export const RESET_ORDER = 'RESET_ORDER'
export const SET_DONE = 'SET_DONE'
